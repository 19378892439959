.ag-theme-alpine .ag-root-wrapper {
  border-top: none;
}
.ag-theme-alpine .ag-header {
  background: #e2e7eb;
  border: 1px solid black;
}
.ag-theme-alpine .ag-cell {
  line-height: 30px;
}

.ag-theme-alpine .ag-header-cell-resize::after {
  background-color: #181d1f63;
}

.ag-theme-alpine .ag-row-odd {
  background-color: rgba(0, 0, 0, 0.03);
}

.ag-theme-alpine .ag-row-selected {
  background-color: rgba(33, 150, 243, 0.3);
}

.ag-theme-alpine .ag-header-cell-label {
  justify-content: center;
}

.ag-theme-alpine .ag-cell {
  text-align: center;
}
