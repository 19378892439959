.ac-sb-nav-link {
  text-decoration: none;
  display: block;
  color: #e4ecfb;
  color: #e4ecfb;
  font-size: 12px;
  font-weight: bold;

  padding: 10px 4px 6px 18px;
  border-radius: 50px 0px 0px 50px;
  margin-bottom: 10px;
  text-transform: uppercase;
  flex-direction: row;
  align-items: center;
  display: flex;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.ac-mini-sidebar .ac-sb-nav-link {
  display: none;
}

.ac-sb-nav-link:hover {
  text-decoration: none;
  background:orange;
  color: white;
}

.ac-sb-nav-link.active {
  text-decoration: none;
  background: orange;
  color: white;
}

.ac-sb-nav-link .icon,
.ac-sb-nav-link:hover .icon {
  display: inline-block;
  width: 30px;
}
.ac-sb-nav-link.active .icon,
.ac-sb-nav-link:hover .icon {
  display: none;
}

.ac-sb-nav-link .icon-active,
.ac-sb-nav-link:hover .icon-active {
  display: none;
}
.ac-sb-nav-link.active .icon-active,
.ac-sb-nav-link:hover .icon-active {
  display: inline-block;
  width: 30px;
}

.ac-sb-separator {
  display: flex;
  background: white;
  height: 1px;
  width: calc(100% - 10px);
  margin-bottom: 10px;
}
.ac-mini-sidebar .ac-sb-separator {
  display: none;
}

.ac-sb-nav-container {
  overflow: auto;
  flex: 1;
 
}
.ac-power-by-block {
  /* position: absolute; */
  bottom: 12px;
  width: 100%;
  text-align: center;
  left: 0;
  color: #e4ecfb;
  padding: 5px 0px;
  display: none;
}

.ac-sb-nav-link path {
  stroke: #5d91d2 !important;
  transition: all 0.15s ease-in-out;
}

.ac-sb-nav-link:hover path {
  stroke: #0786d2 !important;
}

.ac-sb-nav-link.active path {
  stroke: #0786d2 !important;
}

.ac-copy-right {
  text-align: center;
  color: #e4ecfb;
  padding: 5px 0px;
  font-size: 1.2rem;
}

.ac-mini-sidebar .ac-copy-right {
  visibility: hidden;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: relative;
  background-color:black;
  min-width: 180px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  /* padding: 12px 16px; */
  /* z-index: 1; */
}

.dropdown:hover .dropdown-content {
  display: block;
}