.login-page {
  background: url('../css/synergite_login_page.png') ;
  height: 100%;
  width: 100%;
  background-size: 100%;
  background-size: cover;

  .login-form {
    padding-top: 300px;
  

    .login-title {
      border-bottom: 2px solid black;
      display: inline;
      align-items: center;
      font-size: 2.8rem;
      color:black;
      font-family: Arial;
     
    }

    .synicon{
      height: 70px;
      width: 100px;
      padding-left: 0px;
    }

    .forgot-pwd-link {
      float: right;
      color:black;
    
    }
  }
}
