.navbar-custom {
  background-color: black;
  height: 60px;
  font-weight: bold;
}
/* change the brand and text color */
.navbar-custom .navbar-brand,
.navbar-custom .navbar-text {
  color: #e4ecfb;
  color: white;
}
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: #e4ecfb;
  color: white;
}
/* change the link color */
.navbar-custom .navbar-nav .nav-link {
  color: #e4ecfb;
  color: white;
}
/* change the color of active or hovered links */
.navbar-custom .nav-item.active .nav-link,
.navbar-custom .nav-item:hover .nav-link {
  color: #e4ecfb;
  color: white;
}
.navbar-custom .navbar-nav .nav-link:focus,
.navbar-custom .navbar-nav .nav-link.active,
.navbar-custom .navbar-nav .nav-link:hover {
  color: white;
}

.dropdown-menu {
  font-size: 1.4rem;
}
.popover {
  font-size: 1.4rem;
}
.form-control {
  font-size: 1.6rem;
}
.form-control-sm {
  font-size: 1.4rem;
}
.close {
  font-size: 2rem;
}

.five9-confirmation-button {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-around;
  -webkit-box-align: center;
  align-items: center;
  padding-left: 10px;
  flex-basis: 120px;
  line-height: 1.5;
}

.five9-dropdown-container {
  .dropdown-menu {
    padding: 0;
  }
  .five9-container {
    width: 300px;
    line-height: 0;
  }
  // &.show-five9-box {
  //   .dropdown-menu {
  //     display: block;
  //   }
  // }
}

.new-logo {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-left: 7px;
  .new-logo-text {
    font-family: 'Arial';
    font-size: 2.8rem;
    margin-left: 8px;
  }
}
.synlogo{
  height: 40px;
  width: 200px;
  padding-left: 0px;
}