.document-center-title {
  color: #004085;
  font-size: 2.5rem;
  margin-bottom: 20px;
  display: inline-block;
}

.document-msg-box {
  height: calc(100% - 18px);
  display: auto;
  margin: 0;
  width: auto;
}

.file-button {
  position: static;
  display: inline-block;
}

.file-content {
  background-color: #f0f6fa;
  margin-top: 2px;
  margin-bottom: 2px;
}
.file {
  padding: 0px;
  padding-left: 5px;
}
