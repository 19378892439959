.gi-fill-fixone{
    color:black;
}
.nav-tabs-o-left-tabs{
background-color: white;
border: white;
}
.txc{
    color:black;
}
// .one:hover{
//     background-color:orange;
//     border-color:black;
// }
// .two:hover{
//     background-color: orange;
//     border-color: white;
// }
// .three:hover{
//     background-color: orange;
//     border-color: white;
// }
.head{
    width: 320px;
    background-color: white;
}

