.report-tabs-container {
  height: 100%;
  overflow: auto;
  border: 1px solid #c7c7c7;
  padding-top: 8px;
  border-top: 0;

  .left-tabs {
    padding-left: 18px;
    flex: 1;
  }

  .right-tabs {
    padding-right: 5px;
  }

  .nav-tabs .nav-link {
    padding: 6px 15px 1px 15px;
    line-height: 18px;
    height: 32px;
    color: #0786d2;
    &.pending {
      border-bottom: 3px solid #ffc107;
    }
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    border-color: #c7c7c7 #c7c7c7 #fff;
    color: #495057;
    &.pending {
      border-bottom: 3px solid #ffc107;
    }
  }

  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    border-color: #c7c7c7 #c7c7c7 #fff;
    &.pending {
      border-bottom: 3px solid #ffc107;
    }
  }

  .nav-tabs .nav-link.active:hover {
    border-color: #c7c7c7 #c7c7c7 #fff;
    &.pending {
      border-bottom: 3px solid #ffc107;
    }
  }

  .tab-content {
    height: calc(100% - 32px);
    overflow: auto;
    .tab-panel {
      height: 100%;
    }
  }
}

.policy-report-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  .filter-section {
    flex-shrink: 0;
    padding: 10px 0px;
  }
  .grid-section {
    flex: 1;
  }
}

.new-opportunity-report-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  .filter-section {
    flex-shrink: 0;
    padding: 10px 0px;
  }
  .grid-section {
    flex: 1;
  }
}