.contactbutton{
    background-color: black;
    font-size:medium ;
    width: 100px;
    border-radius: 1100px;
    
}
.contactbutton:hover {
    text-decoration: none;
    background:orange;
    color: white;
  }

  .co-table {
}
.co-header:nth-child(1) {
  width: 150px;
}
.co-header:nth-child(2) {
  width: 150px;
}
.co-header:nth-child(3) {
  width: 150px;
}
.co-header:nth-child(4) {
  width: 300px;
}
.co-header:nth-child(5) {
  width: 150px;
}
.co-header:nth-child(6) {
  width: 150px;
}
.co-header:nth-child(7) {
  width: 150px;
}
.co-header:nth-child(8) {
  width: 150px;
}
.co-header:nth-child(9) {
    width: 300px;
  }


.co-actions span svg {
  /* height: 14px;
    width: 14px; */
}

.co-popup-custom-width {
  /* width: 90%;
    max-width: none !important; */
}
.contacts-actions span {
  margin: 0 5px;
  height: 24px;
  width: 24px;
  font-size: 1.6rem;
  line-height: 2rem;
}
