// .nav-tabs .nav-item.show .nav-link,
// .nav-tabs .nav-link.active {
//   border-color: #c7c7c7 #c7c7c7 #fff;
//   color: #495057;
// }

// .nav-tabs .nav-link:focus,
// .nav-tabs .nav-link:hover {
//   border-color: #c7c7c7 #c7c7c7 #c7c7c7;
// }

// .nav-tabs .nav-link.active:hover {
//   border-color: #c7c7c7 #c7c7c7 #fff;
// }

// .o-left-tabs {
//   padding-left: 8px;
//   flex: 1;
// }
// .o-view-more {
//   position: relative;
//   text-align: center;
//   height: 30px;
//   line-height: 30px;
// }

.staging-tabs-container {
  border: 1px solid #c7c7c7;
  padding-top: 20px;
  .o-left-tabs {
    padding-left: 18px;
    flex: 1;
  }

  .o-right-tabs {
    padding-right: 5px;
  }

  .nav-tabs .nav-link {
    padding: 6px 15px 1px 15px;
    line-height: 18px;
    height: 32px;
    color: #0786d2;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    border-color: #c7c7c7 #c7c7c7 #fff;
    color: #495057;
  }

  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    border-color: #c7c7c7 #c7c7c7 #fff;
  }

  .nav-tabs .nav-link.active:hover {
    border-color: #c7c7c7 #c7c7c7 #fff;
  }
}
.staging-upload-box {
  .custom-file {
    cursor: pointer;
  }
  .btn {
    font-size: 1.4rem;
  }
  label {
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 65px;
  }
}
.button{
     
  float: right;
}