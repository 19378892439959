.register-page-box{
    height: calc(120% - 18px);
    
    width: 70%;
    display: block;
    margin-left: 10%;
    
  
  }
  .reg-buttons{
    float: right;
  }
  .base-containerone {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }