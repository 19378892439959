.re-tag-container {
  max-height: 300px;
  overflow: auto;
  margin-bottom: 10px;
}

.re-tag-container .badge {
  font-size: 1.2rem;
  padding: 6px 10px;
  margin-right: 5px;
  margin-top: 8px;
  letter-spacing: 1px;
}
.ac-autocomplete-items {
  font-size: 1.4rem;
  background: #fff;
  padding: 4px 6px;
  cursor: pointer;
}
.ac-autocomplete-items:hover {
  background: #0786d2;
  color: white;
}

.ac-autocomplete-items.active {
  background: #0786d2;
  color: white;
}
