.b-table {
}
.b-header:nth-child(1) {
  width: 100px;
}
.b-header:nth-child(2) {
  width: 150px;
}
.b-header:nth-child(3) {
  width: 200px;
}
.b-header:nth-child(4) {
  width: 150px;
}
.b-header:nth-child(5) {
  width: 150px;
}

