.b-table {
}
.b-header:nth-child(1) {
  width: 90px;
}
.b-header:nth-child(2) {
  width: 375px;
}
.b-header:nth-child(3) {
  width: 100px;
}
.b-header:nth-child(4) {
  width: 150px;
}
.b-header:nth-child(5) {
  width: 140px;
}
.b-header:nth-child(6) {
  width: 140px;
}
.b-header:nth-child(7) {
  width: 140px;
}
.b-header:nth-child(8) {
  width: 140px;
}

.b-actions span svg {
  /* height: 14px;
    width: 14px; */
}

.b-popup-custom-width {
  /* width: 90%;
    max-width: none !important; */
}
.broadcast-actions span {
  margin: 0 5px;
  height: 24px;
  width: 24px;
  font-size: 1.6rem;
  line-height: 2rem;
}
