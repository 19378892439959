.quick-view {
  height: calc(100% - 18px);
  /* padding-bottom: 18px; */

  .quick-view-ac-box {
    height: 100%;

    .quick-view-title {
      color: #004085;
      font-size: 2rem;
    }
    .quick-view-row {
      background: #0b1481;
      margin: 18px 0px;
      display: flex;
      align-items: center;
      color: white;
      padding: 8px 3px;
      position: relative;
      left: -16px;
      height: 64px;
      height: 50px;

      background: #cce5ff;
      color: #004085;
      border: 0px solid #a6bacf;
      border-right: 0;

      .quick-view-circle {
        display: inline-block;
        height: 12px;
        width: 12px;
        background: white;
        border-radius: 12px;
        margin: 0px 8px;
        background: #1157a2;
      }
      .quick-view-text {
        font-size: 1.8rem;
      }
      .quick-view-badge {
        background: white;
        border-radius: 6px;
        margin: 0 10px;
        color: #004085;
        display: inline-block;
        padding: 0 15px;
        cursor: pointer;
        font-size: 1.6rem;
      }
      .quick-view-badge-link {
        background: white;
        border-radius: 6px;
        margin: 0 10px;
        color: #004085;
        display: inline-block;
        padding: 0 15px;
        cursor: pointer;
        text-decoration: none;
        font-size: 1.6rem;
        border: 1px solid #a6bacf;
      }

      .quick-view-arrow {
        position: absolute;
        width: 0px;
        height: 0px;
        border-top: 25px solid transparent;
        border-bottom: 25px solid transparent;
        border-left: 25px solid #cce5ff;
        right: 0;
        background: white;
      }

      // &.pomo {
      //   background: #c0392b;
      //   background: #d63031;
      //   .quick-view-arrow {
      //     border-left: 25px solid #c0392b;
      //     border-left: 25px solid #d63031;
      //   }
      // }
      // &.blue {
      //   background: #3498db;
      //   background: #1b9cfc;
      //   .quick-view-arrow {
      //     border-left: 25px solid #3498db;
      //     border-left: 25px solid #1b9cfc;
      //   }
      // }
      // &.green {
      //   background: #2ecc71;
      //   .quick-view-arrow {
      //     border-left: 25px solid #2ecc71;
      //   }
      // }
      // &.mint {
      //   background: #00b894;
      //   .quick-view-arrow {
      //     border-left: 25px solid #00b894;
      //   }
      // }
      // &.purple {
      //   background: #6c5ce7;
      //   .quick-view-arrow {
      //     border-left: 25px solid #6c5ce7;
      //   }
      // }
    }
  }
}

.broadcast-msg-box {
  height: calc(100% - 18px);

  .broadcast-msg-title {
    color:black;
    font-size: 2rem;
    margin-bottom: 7px;
    display: inline-block;
  }
  .alert-header {
    margin-bottom: 10px;
    border-bottom: 1px solid #0000002e;
    padding: 7px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .alert-title {
    flex: 1;
  }

  .alert-time {
    font-size: 1.2rem;
  }
}

.my-opportunities-box {
  .my-opportunities-title {
    color: #004085;
    font-size: 2rem;
    margin-bottom: 7px;
  }

  .chart-number-box {
    background: white;
    margin-bottom: 18px;
    padding: 20px 15px 0px 15px;
    /* box-shadow: 0px 0px 10px 0px rgba(74, 133, 249, 0.25); */
    border-radius: 4px;
    border: 1px solid #004085;

    h4 {
      color: #777;
      color: #004085;
      letter-spacing: 1px;
      font-weight: 600;
      padding-bottom: 7px;
      margin-bottom: 10px;
      border-bottom: 1px solid #c7c7c7;
      position: relative;

      &.chart-number-h4-active {
        .chart-number-active {
          background-color: #ffebee;
          color: #f44336;
          border-radius: 140px;
          display: inline-flex;
          font-weight: 600;
          height: 30px;
          line-height: 30px;
          padding: 0 15px;
          justify-content: center;
          min-width: 50px;
          align-items: center;
          position: absolute;
          right: 0;
          top: -9px;
          cursor: pointer;

          .spinner-border-sm {
            width: 1.8rem;
            height: 1.8rem;
            color: #f44336 !important;
          }
        }
        .chart-number-active svg {
          height: 20px;
          width: 20px;
          position: relative;
          top: -1px;
          margin-right: 4px;
        }
      }
    }

    .chart-number-last-update {
      color: #777;
      margin-top: 10px;
      border-top: 1px solid #c7c7c7;
      padding: 7px 0;
      font-size: 1.2rem;
    }
  }

  .chart-number-row {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    color: #004085;
    margin: 8px 0;

    .chart-number-i {
      display: inline-block;
      height: 10px;
      width: 10px;
      background: #0040858a;
      border-radius: 5px;
      margin-right: 8px;
    }
    .chart-number-label {
      flex: 1;
    }

    .chart-number-value {
      border-radius: 140px;
      display: inline-flex;
      font-weight: 600;
      height: 30px;
      letter-spacing: 0.2px;
      line-height: 30px;
      padding: 0 15px;
      justify-content: center;
      align-items: center;
      min-width: 60px;

      background-color: rgb(232, 245, 233);
      color: rgb(76, 175, 80);

      &.green {
        background-color: rgb(232, 245, 233);
        color: rgb(76, 175, 80);
        .spinner-border-sm {
          color: rgb(76, 175, 80) !important;
        }
      }

      &.red {
        background-color: #ffebee;
        color: #f44336;
        .spinner-border-sm {
          color: #f44336 !important;
        }
      }

      &.purple {
        background-color: #f3e5f5;
        color: #9c27b0;
        .spinner-border-sm {
          color: #9c27b0 !important;
        }
      }

      &.blue {
        background-color: #e3f2fd;
        color: #2196f3;
        .spinner-border-sm {
          color: #2196f3 !important;
        }
      }

      .spinner-border-sm {
        width: 1.8rem;
        height: 1.8rem;
      }
    }
  }

  .active-opportunities {
    display: none;
  }
}

.team-radar-box {
  height: calc(100% - 18px);

  .team-radar-title {
    color: #004085;
    font-size: 2rem;
  }

  .ac-radar-container {
    height: 300px;
  }
}
